import { CodeError, fetchAPI } from "actions/actions";
import { BAD_REQUEST_ERROR_CODE, CONFLICT_ERROR_CODE, FIREBASE_WRONG_PASSWORD, UNAUTHORIZED_ERROR_CODE } from "constants/api_errors";
import { Collections, DBIdentifiers, DbOrder } from "constants/db";
import { QueryFilter } from "constants/types";
import urls from "constants/urls";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Timestamp, updateDoc } from "firebase/firestore";
import { getDocumentReference, listDocs, retrieveDocument } from "helpers/db";
import moment from "moment";
import { MessagesActions } from "store/reducers/message";
import { showError, showSuccess, showTranslatedMessage } from "store/reducers/snacks";
import { UsersActions } from "store/reducers/users";
import { AppDispatch } from "store/store";
import { CoconDbData } from "./Cocons/Cocon";

type CoconData = Pick<CoconDbData, "address" | "city" | "postcode" | "joinCode"> & {
    clusterId: string;
    id: string;
}

export type AddressData = {
    street_address: string;
    additional_information?: string;
    city: string;
    postcode: string;
}

export type UserDbData = {
    email?: string;
    phone?: string;
    firstName: string;
    lastName: string;
    score: number;
    balance: number;
    rank?: string;
    nextRankPercentage?: number;
    pin: number;
    cocon?: CoconData;
    role?: string;
    batchesCount: number;
    savedCO2: number;
    savedMoney: number;
    locale: string;
    dateJoined: Timestamp;
    address?: AddressData;
    hasOpenTicket?: boolean;
}

type User = UserDbData & DBIdentifiers;

const COLLECTION = Collections.USERS;

const getDateJoined = (user: User) => moment(user.dateJoined.toDate());

const getDisplayName = (user: User) => {
    if (user.firstName && user.lastName) return `${user.firstName} ${user.lastName}`;
    else if (user.email) return user.email;
    else if (user.pin) return `Pin: ${formatPin(user.pin)}`;
    else return "? ?";
}

const formatPin = (pin: number) => pin.toString().padStart(4, '0');

const formatAddress = (address: User["address"]) => {
    if (address) return `${address.street_address}, ${address.additional_information}, ${address.postcode} ${address.city}`;
    return "";
}

const signIn = (email: string, password: string) => async (dispatch: AppDispatch) => {
    dispatch(UsersActions.startSignIn());

    // login through Firebase to get a token to access the API
    try {
        await signInWithEmailAndPassword(getAuth(), email, password)
    }
    catch (e) {
        const error = new CodeError(400, (e as Error).message);
        dispatch(UsersActions.setSignInError(error.message));
        // show error message
        if (error.code === 400 || error.message === FIREBASE_WRONG_PASSWORD) {
            dispatch(showError("L'adresse email et le mot de passe ne correspondent pas."));
        }
        else {
            dispatch(showError("Impossible de contacter notre serveur. Es-tu bien connecté·e à internet?"));
        }
    }
}

const list = (filters: QueryFilter[], order?: DbOrder) => async (dispatch: AppDispatch) => {
    dispatch(UsersActions.startLoadingList());

    try {
        const users = await listDocs<User>(COLLECTION, filters, order);
        dispatch(UsersActions.setList(users));
        return users;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(UsersActions.setListError(error.message));
        dispatch(showError(error.message)); // show error message
        return [];
    }
}

const autocompleteManagers = (pattern: string) => async (dispatch: any) => {
    dispatch(UsersActions.startLoadingList);
    try {
        const managers = await fetchAPI(`${urls.API}/user/manager/autocomplete?pattern=${pattern}`);
        dispatch(UsersActions.setManagers(managers));
        return managers;
    } 
    catch (e) {
        const error = e as CodeError;
        dispatch(UsersActions.setManagersError(error.message));
        dispatch(showError(error.message)); // show error message
        return [];
    }
}

const retrieve = (id: string) => async (dispatch: AppDispatch) => {
    dispatch(UsersActions.startLoadingSelected());

    try {
        const user = await retrieveDocument<User>(COLLECTION, id);
        dispatch(UsersActions.setSelected(user));
        dispatch(MessagesActions.setMessagesList([])); // reset new user's message
        return user;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(UsersActions.setSelectedError(error.message));
        dispatch(showError(error.message));
        return null;
    }
}

const update = (user: User, data: Partial<User>) => async (dispatch: AppDispatch) => {
    dispatch(UsersActions.startLoadingSelected());

    try {
        const docRef = getDocumentReference(user.id, COLLECTION);
        await updateDoc(docRef, data);

        dispatch(UsersActions.setSelected({
            ...user,
            ...data,
        }));

        dispatch(showTranslatedMessage({
            variant: "success",
            messageKey: "update_user.success",
        }));

        return user;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(UsersActions.setSelectedError(error.message));
        dispatch(showError(error.message));
        return null;
    }
}

export const verifyEmailAddress = (token: string) => async (dispatch: AppDispatch) => {
    dispatch(UsersActions.startLoadingLoggedOut());

    try {
        await fetch(`${urls.API}/user/verify-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
            },
        });
        dispatch(UsersActions.doneLoadingLoggedOut());
        return true;
    }
    catch (e) {
        const err = e as CodeError;
        // don't dispatch 401 error which would redirect to login page
        const error = new CodeError(
            err.code === UNAUTHORIZED_ERROR_CODE ? BAD_REQUEST_ERROR_CODE : CONFLICT_ERROR_CODE,
            err.code === UNAUTHORIZED_ERROR_CODE ? "invalid_link" : "verify_email.already_done");
        dispatch(UsersActions.setLoggedOutError(error.message));
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: error.message,
        }));
        return false;
    }
}

export const completeProfile = (token: string, addressData: AddressData) => async (dispatch: AppDispatch) => {
    dispatch(UsersActions.startLoadingLoggedOut());

    try {
        await fetch(`${urls.API}/user/complete-profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': token,
            },
            body: JSON.stringify(addressData),
        });
        dispatch(UsersActions.doneLoadingLoggedOut());
        return true;
    }
    catch (e) {
        const error = e as CodeError;
        dispatch(UsersActions.setLoggedOutError(error.message));
        dispatch(showTranslatedMessage({
            variant: "error",
            messageKey: "something_went_wrong",
        }));
        return false;
    }
}

export default User;

export const UsersMethods = {
    getDateJoined,
    getDisplayName,
    formatPin,
    formatAddress,
    signIn,
    list,
    autocompleteManagers,
    retrieve,
    update,
    verifyEmailAddress,
    completeProfile,
}