import { Namespaces } from "locales/translations";
import SectionLoader from "../../../_include/SectionLoader";
import { useTranslation } from "react-i18next";
import ChartTitle from "./ChartTitle";
import ChartWrapper from "../ChartWrapper";
import { useAppSelector } from "store/hooks";
import PieChart from "./PieChart/PieChart";
import { ResponsivePie } from "@nivo/pie";
import { Box, Typography } from "@mui/material";
import { TrashType } from "constants/trash";
import TooltipWrapper from "./TooltipWrapper";
import PieChartCenterText from "./PieChart/PieChartCenterText";
import _ from "lodash";

type ChartProps = {

}

function TrashTypesChart(props: ChartProps) {

    const { t } = useTranslation([Namespaces.glossary, Namespaces.stats, Namespaces.wastes]);

    const trashesStats = useAppSelector(state => state.stats.trashes);
    const nbBatches = useAppSelector(state => state.stats.totalTrashes);

    const loading = useAppSelector(state => state.stats.loading);

    const margin = window.innerWidth > 800 ? { top: 40, right: 150, bottom: 40, left: 150 } : { top: 0, right: 150, bottom: 0, left: 150 };

    return (
        <ChartWrapper>
            <ChartTitle
                title={t("trash_types", {
                    ns: Namespaces.stats,
                })}
            />
            {loading ? (
                <SectionLoader />
            ) : (
                <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    position="relative"
                    width="100%"
                    height="100%"
                >
                    <ResponsivePie
                        data={trashesStats}
                        margin={margin}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={{ scheme: 'nivo' }}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextOffset={6}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsOffset={0}
                        arcLinkLabelsDiagonalLength={16}
                        arcLinkLabelsStraightLength={12}
                        arcLinkLabelsThickness={1}
                        arcLinkLabelsColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        arcLinkLabel={function (e) { return `${e.label}` }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor="#333333"
                        arcLabel={function (e) { return `${e.value}%` }}
                        animate={true}
                        tooltip={({ datum }) => (
                            <TooltipWrapper>
                                <Typography variant="body2">
                                    {`${datum.value} ${_.lowerFirst(t(datum.id.toString(), { ns: Namespaces.wastes, count: datum.value }))}`}
                                </Typography>
                            </TooltipWrapper>
                        )}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: TrashType.METAL
                                },
                                id: 'lines'
                            },
                            {
                                match: {
                                    id: TrashType.PAPER
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: TrashType.BROWN_CARDBOARD
                                },
                                id: 'dots'
                            },
                        ]}
                    />

                    <PieChartCenterText
                        centerText={t("batches_received_count", { ns: Namespaces.stats, count: nbBatches })}
                        margin={margin}
                    />
                </Box>
            )}
        </ChartWrapper>
    )
};

export default TrashTypesChart;