import { Box, FormControl, InputLabel, MenuItem, Select, Slider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { QueryFilters } from "../../../constants/types";
import { QueryFilter } from "constants/types";
import { Namespaces } from "../../../locales/translations";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ActionButton from "../../_include/ActionButton";
import { CoconsClustersMethods } from "models/Cocons/CoconsCluster";
import { Timestamp } from "firebase/firestore";
import { selectAllCoconsClusters } from "store/reducers/cocons/clusters";
import CoconsClusterSelect from "components/_include/Filters/CoconsClusterSelect";

type ContactsFilterFormProps = {
    defaultValues: QueryFilters;
    search: (filters: QueryFilter[]) => void;
}

function ContactsFilterForm(props: ContactsFilterFormProps) {
    const { t } = useTranslation([Namespaces.forms, Namespaces.glossary, Namespaces.stats]);

    const { defaultValues, search } = props;

    const usersLoading = useAppSelector(state => state.users.users.loading);
    const dispatch = useAppDispatch();

    const [coconsClusterId, setCoconsClusterId] = useState(defaultValues["coconsCluster.id"] || "");
    const [batchesCount, setBatchesCount] = useState(Number(defaultValues["batchesCount"]) || 0);
    const [score, setScore] = useState(Number(defaultValues["score"]) || 0);
    const [savedCO2, setSavedCO2] = useState(Number(defaultValues["savedCO2"]) || 0);
    const [savedMoney, setSavedMoney] = useState(Number(defaultValues["savedMoney"]) || 0);

    const handleFilterPressed = () => {
        let filters: QueryFilter[] = [];

        if (coconsClusterId) {
            filters.push({ fieldPath: "cocon.clusterId", opStr: "==", value: coconsClusterId });
        }

        filters.push({ fieldPath: "batchesCount", opStr: ">=", value: batchesCount });
        filters.push({ fieldPath: "score", opStr: ">=", value: score });
        filters.push({ fieldPath: "savedCO2", opStr: ">=", value: savedCO2 });
        filters.push({ fieldPath: "savedMoney", opStr: ">=", value: savedMoney });

        search(filters);
    }

    const valueText = (value: number) => {
        return `${value}`;
    }

    return (
        <Stack
            direction="column"
            alignItems="stretch"
            spacing={4}
            sx={{
                padding: 2,
            }}
        >
            {/* Cocon filter */}
            <CoconsClusterSelect
                parentLoading={false}
                value={coconsClusterId}
                noClusterSelectedLabel={t("contacts.all_cocons", { ns: Namespaces.forms })}
                deployed
                onChange={(cluster) => { setCoconsClusterId(cluster?.id || ""); }}
                />

            {/* Score filter */}
            <Box>
                <Typography id="score-slider" gutterBottom>
                    {t("minimum_score", { ns: Namespaces.glossary, count: score })}
                </Typography>
                <Slider
                    value={score}
                    getAriaValueText={valueText}
                    aria-labelledby="score-slider"
                    valueLabelDisplay="auto"
                    step={2}
                    min={0}
                    max={100}
                    onChange={(e, value) => setScore(Number(value))}
                />
            </Box>

            {/* Nb batches filter */}
            <Box>
                <Typography id="nb-batches-slider" gutterBottom>
                    {t("minimum_batches_count", { ns: Namespaces.glossary, count: batchesCount })}
                </Typography>
                <Slider
                    value={batchesCount}
                    getAriaValueText={valueText}
                    aria-labelledby="nb-batches-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={20}
                    onChange={(e, value) => setBatchesCount(Number(value))}
                />
            </Box>

            {/* Saved money filter */}
            <Box>
                <Typography id="saved-money-slider" gutterBottom>
                    {t("minimum_money_saved", { ns: Namespaces.stats, count: savedMoney })}
                </Typography>
                <Slider
                    value={savedMoney}
                    getAriaValueText={valueText}
                    aria-labelledby="saved-money-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={100}
                    onChange={(e, value) => setSavedMoney(Number(value))}
                />
            </Box>

            {/* Saved CO2 filter */}
            <Box>
                <Typography id="saved-co2-slider" gutterBottom>
                    {t("minimum_co2_saved", { ns: Namespaces.stats, count: savedCO2 })}
                </Typography>
                <Slider
                    value={savedCO2}
                    getAriaValueText={valueText}
                    aria-labelledby="saved-co2-slider"
                    valueLabelDisplay="auto"
                    step={0.5}
                    min={0}
                    max={10}
                    onChange={(e, value) => setSavedCO2(Number(value))}
                />
            </Box>

            <Box sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
            }}>
                <ActionButton
                    color="secondary"
                    disabled={usersLoading}
                    loading={usersLoading}
                    onClick={handleFilterPressed}
                    // style={{
                    //     flexGrow: 1,
                    //     display: "flex",
                    // }}
                >
                    {t("filter", { ns: Namespaces.actions })}
                </ActionButton>
            </Box>
        </Stack>
    );
}

export default ContactsFilterForm;