import { Collections, DBIdentifiers, DbOrder } from "constants/db";
import { QueryFilter } from "constants/types";
import { Query, DocumentData, query, where, limit, orderBy, getDocs, getFirestore, collection, collectionGroup, QueryDocumentSnapshot, DocumentSnapshot, doc, getDoc, addDoc, DocumentReference, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import _ from "lodash";

export function formatQuery(collectionRef: Query<DocumentData>, filters?: QueryFilter[], order?: DbOrder, limitCount?: number) {
    let q = query(collectionRef);

    const inequalityFilters = filters?.filter(f => f.opStr !== "==");
    let applyFiltersLater = false;
    if (inequalityFilters && inequalityFilters.length > 1) {
        let uniqueFieldPath: string[] = [];
        for (const { fieldPath } of inequalityFilters) {
            if (!uniqueFieldPath.includes(fieldPath)) {
                uniqueFieldPath.push(fieldPath);
                if (uniqueFieldPath.length > 1) { // inequality filter on more that one field
                    applyFiltersLater = true;
                    break;
                }
            }
        }
    }

    let inequalityFilterApplied: string | null = null;

    if (filters) {
        filters.forEach(({ fieldPath, opStr, value }) => {
            if (opStr === "==" || !applyFiltersLater || !inequalityFilterApplied || inequalityFilterApplied === fieldPath) { // more than 1 inequality filters: cannot all be applied to Firestore
                q = query(q, where(fieldPath, opStr, value));
                if (opStr !== "==") inequalityFilterApplied = fieldPath;
            }
        });
    }

    if (order) {
        q = query(q, orderBy(order.fieldPath, order.directionStr));
    }

    if (limitCount) {
        q = query(q, limit(limitCount));
    }
    return {
        query: q,
        inequalityFilters: applyFiltersLater ? inequalityFilters?.filter(filter => filter.fieldPath !== inequalityFilterApplied) : undefined,
    };
}

const getNestedField = (fieldPath: string) => fieldPath.split(".");

export function applyQueryFilterToDoc(filter: QueryFilter, doc: QueryDocumentSnapshot) {
    const data = doc.data();
    let field = data;
    for (let level of getNestedField(filter.fieldPath)) {
        field = field[level];
    }
    switch (filter.opStr) {
        case "<": return field < filter.value;
        case "<=": return field <= filter.value;
        case ">": return field > filter.value;
        case ">=": return field >= filter.value;
        case "!=": return field && field != filter.value;
        case "in": return filter.value.includes(field);

        case "array-contains-any": 
            // eg: doc field [{id: 1}, {id: 3}] contains filter [{id: 1}, {id: 2}]
            return filter.value.some(
                (filterItem: any) => field.some(
                    (fieldItem: any) => _.isEqual(filterItem, fieldItem)
                )
            );
    }
    return true;
}

export function applyQueryFilter(filter: QueryFilter, docs: QueryDocumentSnapshot[]) {
    return docs.filter(doc => applyQueryFilterToDoc(filter, doc));
}

export const getCollectionRef = (col: Collections, path?: string) => {
    const db = getFirestore();
    if (path) return collection(db, path, col);
    return collection(db, col);
}

export const getCollectionGroupRef = (col: Collections, ) => {
    const db = getFirestore();
    return collectionGroup(db, col);
}

export const getDocumentReference = (id: string, collection: Collections, path?: string, ) => {
    const db = getFirestore();
    if (path) return doc(db, path, collection, id);
    return doc(db, collection, id);
}

export function fromSnapshot(dbDoc: QueryDocumentSnapshot<DocumentData>) {
    const data = dbDoc.data();
    return {
        ...data,
        id: dbDoc.id,
        // ref: dbDoc.ref,
    };
}

export function fromDocSnapshot(dbDoc: DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data();

    if (!data) return null;
    return {
        ...data,
        id: dbDoc.id,
        // ref: dbDoc.ref,
    };
}

export async function listDocs<T extends DBIdentifiers>(collection: Collections, filters?: QueryFilter[], order?: DbOrder, limitCount?: number) { 
    const collectionRef = getCollectionRef(collection);

    const { query, inequalityFilters } = formatQuery(collectionRef, filters, order, limitCount);

    const snapshot = await getDocs(query);

    // apply inequality filters if more than 1 was specified
    let documents = snapshot.docs;
    inequalityFilters?.forEach(filter => {
        documents = applyQueryFilter(filter, documents);
    })

    return documents.map(doc => fromSnapshot(doc) as T);
}

export async function listSubcollectionDocs<T extends DBIdentifiers>(collection: Collections, path?: string, filters?: QueryFilter[], order?: DbOrder, limitCount?: number) { 
    const collectionRef = path ? getCollectionRef(collection, path) : getCollectionGroupRef(collection);

    const { query, inequalityFilters } = formatQuery(collectionRef, filters, order, limitCount);

    const snapshot = await getDocs(query);

    // apply inequality filters if more than 1 was specified
    let documents = snapshot.docs;
    inequalityFilters?.forEach(filter => {
        documents = applyQueryFilter(filter, documents);
    })

    return documents.map(doc => fromSnapshot(doc) as T);
}

export async function retrieveDocument<T extends DBIdentifiers>(collection: Collections, id: string) {      
    const docRef = getDocumentReference(id, collection);
    const modelDoc = await getDoc(docRef);
    return fromDocSnapshot(modelDoc) as T;
}

export async function getSubcollectionDoc(collection: Collections, id: string, path: string) {      
    const docRef = getDocumentReference(id, collection, path);
    const modelDoc = await getDoc(docRef);
    return fromDocSnapshot(modelDoc);
}

export async function createDocument<T extends DocumentData & DBIdentifiers>(collection: Collections, data: Pick<T, Exclude<keyof T, keyof DBIdentifiers>>) {
    const docRef = doc(getCollectionRef(collection));
    await setDoc(docRef, { ...data, id: docRef.id });
    
    return {
        ...data,
        id: docRef.id,
        // ref: dbDoc,
    } as T;
}

export async function updateDocument<T extends DocumentData & DBIdentifiers>(ref: DocumentReference, dbModel: T, data: DocumentData) {
    await updateDoc(ref, data);

    // update object properties
    return { ...dbModel, ...data } as T;
}

export async function deleteDocument(ref: DocumentReference) {
    return await deleteDoc(ref);
}
